.input-field {
    padding: 12px 5px;
    input {
      font-size: 16px;
      display: block;
      width: 100%;
      max-width: 350px;
      padding: 10px 1px;
      border: 0;
      border-bottom: 1px solid #747474;
      outline: none;
      transition: all .2s;
      &::placeholder {
        text-transform: uppercase;
      }
      
      &:focus {
        border-color: #222;
      }
    }
  }

  .btn {
      margin: 2rem 0 1rem 0;
      padding: 0.5rem 2rem;
      background-color: $color-primary;
      color: white;
      border: 0;
      cursor: pointer;
  }