.container {
    max-width: 1200px;
    min-width: 800px;
    width: 80%;
    min-height: 100vh;
    margin: 0 auto;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
}

.header {
    height: 300px;
    width: 100%;
    background-image: url("/assets/images/hero_image.jpg");
    background-size: 130%;
    background-position: 100px -180px;

    &__logo {
        display: inline-block;
        margin: 2rem 0 0 2rem;
        padding: 0.6rem 1.8rem;
        background-color: white;

        h1 {
            font-size: 4.375rem;
            font-weight: normal;
        }
    }
}

.navigation {
    width: 100%;
    padding: 0.2rem 1rem;
    display: flex;
    justify-content: space-between;
    background-color: $color-primary;
    color: white;
    font-size: 1.5rem;
    font-family: "Rozha One";

    a {
        color: white;
        text-decoration: none;
    }

    ul {
        list-style: none;
        display: flex;

        & > li:not(:first-child) {
            margin-left: 1rem;
        }

        li:hover {
            border-bottom: 2px solid white;
        }
    }

    &--active {
        border-bottom: 2px solid white;
    }
}

.content {
    min-height: 100%;
    padding: 2rem 2rem;
}

.page-title {
    font-size: 3.5rem;
}
