.cds {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    flex-wrap: wrap;

    &__card {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 48%;
        padding: 2rem 3rem;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
        text-align: center;
        border-radius: 5px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 48%;
        margin: 1.5rem 0;

        h3 {
            font-size: 1.75rem;
            font-family: $font-secondary;
            font-weight: bold;
        }

        .image {
            width: 100%;
            margin: 1rem 0;
        }
    }

    &__amazon {
        width: 50%;
    }
}

.cd-button {
    padding: 1rem;
    background-color: #7b1b20;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 1.2rem;
    cursor: pointer;
    outline: none;
}
