@import "colors";
@import "fonts";

*,
*:before,
*:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    font-size: 16px;
}

body {
    font-family: $font-secondary;
    min-height: 100vh;
    color: $color-primary;
    background-color: $color-bg;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-primary;
    font-weight: normal;
}

a {
    color: $color-primary;
}

.legal {
    padding: 2rem 0;
    text-align: center;
    font-size: 0.7rem;
}

@import "base";
@import "dates";
@import "vita";
@import "roles";
@import "cds";
@import "contact";
@import "form";

.cookie-consent {
    display: fixed;
    padding: 0.5rem 2rem;
    top: 0px;
    width: 100%;
    background-color: $color-primary;
    color: white;

    button {
        margin-left: 2rem;
        color: $color-primary;
        background-color: white;
        padding: 0.5rem;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 15px;;
    }
}
