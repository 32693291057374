.vita {
    display: flex;
    width: 100%;
    min-height: 500px;

    &__block {
        position: relative;
        height: 100%;
        width: 50%;

        .img1 {
            position: absolute;
            width: 40%;
            z-index: 20;
            top: 35%;
            left: 10%;
        }
    
        .img2 {
            position: absolute;
            top: 5%;
            left: 20%;
            width: 70%;
            z-index: 10;
        }

        p {
            margin-bottom: 2rem;
        }

        button {
            padding: 1rem;
            background-color: $color-primary;
            color: white;
            border: none;
            border-radius: 10px;
            font-size: 1.2rem;
        }
    }
}
