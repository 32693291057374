.roles {
    display: flex;
    width: 100%;

    &__block-img {
        width: 50%;
        position: relative;
        min-height: 500px;

        .img1 {
            position: absolute;
            top: 15%;
            left: 20%;
            width: 50%;
            z-index: 10;
        }

        .img2 {
            position: absolute;
            width: 40%;
            z-index: 20;
            top: 50%;
            left: 5%;
        }
    }

    &__block {
        width: 50%;
        position: relative;
    }

    ul {
        list-style: none;
    }
}
