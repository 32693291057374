.sort-buttons {
    display: flex;
    justify-content: space-between;

    &__btn {
        padding: 0.5rem 1rem;
        color: $color-primary;
        background-color: transparent;
        border: 1px solid $color-primary;
        border-radius: 20px;
        cursor: pointer;
        outline: none;
    }

    &__btn--gray {
        color: $color-gray-1;
        border-color: $color-gray-1;
    }

    &__search {
        padding: 0.5rem 1rem;
        background-color: white;
        color: $color-gray-1;
        border: none;
        border-radius: 20px;
    }
}

.table-dates {
    margin: 2rem 0;

    h3 {
        margin-top: 3rem;
        font-size: 3rem;
        line-height: 3rem;
    }

    h3 + h4 {
        margin-top: 0rem !important;
    }

    h4 {
        margin-top: 1rem;
        font-size: 1.75rem;
        font-family: "Open Sans";
        font-weight: bold;
    }

    .divider {
        width: 100%;
        height: 2px;
        background-color: $color-gray-1;
    }

    &__element {
        display: flex;
        padding: 0.4rem 0 0.4rem 1rem;
        align-items: center;
        justify-content: space-between;

        .date {
            width: 5%;
            font-family: $font-primary;
            font-size: 2rem;
            line-height: 2rem;
        }

        .event {
            font-size: 1.3rem;
            font-weight: 700;
            text-align: left;
            width: 35%;
        }

        .event_sort {
            font-size: 1.3rem;
            font-weight: 700;
            text-align: left;
            width: 25%;
        }

        .venue_sort {
            margin-left: 3rem;
            width: 50%;
        }

        .venue {
            margin-left: 3rem;
            width: 30%;
        }

        .time {
            margin-left: 3rem;
            width: 10%;
        }
    }
}

.btn-info {
    border: none;
    background-color: $color-primary;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 15px;
    cursor: pointer;
}

.btn-info:active,
.btn-info:focus {
    outline: none;
}

.spacer {
    margin-top: 2rem;
}

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0%;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    overflow: hidden;
}

/* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    margin: 5% auto 5% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
    max-height: 80%;
    overflow-y: auto;
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.news-box {
    border: 2px solid $color-primary;
    padding: 1rem 1.5rem;
    margin-bottom: 2rem;

    .date {
        color: #CACACA;
        font-size: 0.8rem;
    }

    h2 {
        font-size: 2rem;
        font-family: $font-primary;
    }

    .divider {
        margin-bottom: 0.5rem;
        width: 100%;
        height: 2px;
        background-color: $color-gray-1;
    }
}

.modal-info {
    text-align: center;
    margin-top: 1.5rem;

    h1 {
        font-size: 2rem;
        margin-top: 1.5rem;
        font-family: $font-secondary;
        font-weight: bold;
    }

    h4 {
        margin-top: 0;
        margin-bottom: 2rem;
        font-size: 1.2rem;
        font-weight: normal;
    }

    a {
        border: none;
        background-color: #7b1b20;
        color: white;
        padding: 0.5rem 1rem;
        border-radius: 5px;
    }
}

.modal-flex {
    display: flex;
    width: 100%;
    padding: 2rem;

    .img {
        text-align: center;
    }

    .text {
        padding-right: 2rem;
    }

    div {
        width: 50%;
    }
}

.event-modal__image {
    max-height: 50vh;
}