.contact {
    display: flex;
    margin-top: 2rem;

    &__legal-link {
        margin-top: 2rem;
        margin-left: 2rem;
    }

    &__friends {
        margin-top: 2.5rem;
    }

    &__submit {
        padding: 1rem 2rem;
        background-color: $color-primary;
        color: white;
        border-radius: 5px;
        width: 80%;
        border: none;
        text-align: left;
        font-size: 1.2rem;
        margin: 0 2rem;
        cursor: pointer !important;
    }

    div {
        width: 50%;

        input,
        textarea {
            padding: 1rem 2rem;
            margin: 0.5rem 2rem;
            width: 80%;
            border: 1px solid $color-primary;
            border-radius: 5px;
            font-size: 1.1rem;
            font-family: $font-secondary;
            color: $color-primary;
        }

        h3,
        h4 {
            margin-bottom: 1rem;
            font-family: $font-secondary;
        }
    }
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}
.alert {
    width: 100% !important;
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}
